<template>
    <div>
        <div>
            <el-card class="box-card mt-0 mb-0 pr-1 pl-1">
                <div class="col-12">
                    
                    <div class="col-4 mt-0 mb-0" style="display:inline-block">
                        {{ $t('message.balance') }}: {{ total_prices | formatMoney }}
                    </div>
                    <div class="col-3 mt-0 mb-0" style="display:inline-block">
                        {{ $t('message.remaind') }}: {{ (remainder_prices > 0 ? remainder_prices : 0) | formatMoney }}
                    </div>
                    <div class="col-5 mt-0 mb-0" style="display:inline-block">
                        {{ $t('message.all_not_paid_price') }}: {{ not_paid_prices | formatMoney }}
                    </div>
                </div>
            </el-card>
        </div>
        <el-card shadow="always">
            <el-row>
                <el-col :xs="24" :sm="24">
                    <el-button :loading="orderButtonLoading" @click="orderList" type="primary" icon="el-icon-search" radial>{{ $t('message.search_order') }}</el-button>
                </el-col>
                <el-col :xs="24" :sm="24" class="button_r">
                    <el-button :loading="loadingButton" @click="submit" v-if="orderListShow" type="success">{{ $t('message.pay_order') }}</el-button>
                </el-col>
            </el-row>
            <div v-if="orderListShow">
                <div class="mt-2 mb-2">
                    <el-switch
                    v-model="changeOrders"
                    @change="autoChange"
                    inactive-text="Автоматический выбор">
                    </el-switch>
                </div>
                <!-- <h6 class="mt-2">Заявки</h6> -->
                <el-table
                    v-loading="loadingdata"
                    ref="multipleTable"
                    :data="orders"
                    style="width: 100%"
                    :row-class-name="tableRowClassName"
                    @selection-change="handleSelectionChange">
                    <el-table-column
                    type="selection"
                    width="55">
                    </el-table-column>

                    <el-table-column
                    :label="$t('message.patient')"
                    width="250">
                    <template slot-scope="scope">
                        {{ scope.row.patient ? scope.row.patient.surname : ' '}} {{ scope.row.patient ? scope.row.patient.first_name :'' }} {{scope.row.id}}
                    </template>
                    </el-table-column>

                    <el-table-column
                    property="day"
                    :label="$t('message.date_0')"
                    width="150">
                    </el-table-column>

                    <el-table-column
                    property="total_price"
                    :label="$t('message.cost_order')"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.total_price | formatMoney }}
                    </template>
                    </el-table-column>

                    <el-table-column
                    property="not_paid"
                    :label="$t('message.not_paid_sum')"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.not_paid | formatMoney }}
                    </template>
                    </el-table-column>

                    <el-table-column
                    property="paying_price"
                    :label="$t('message.payable_sum')"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.paying_price | formatMoney }}
                    </template>
                    </el-table-column>

                    <el-table-column
                    property="notes"
                    :label="$t('message.note')"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.notes ? scope.row.notes : (scope.row.paying_price ? $t('message.contractual_basis') : '') }}
                    </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        

    </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex';
    export default {
        data(){
            return {
                total_prices:0,
                remainder_prices:0,
                not_paid_prices:0,
                changeOrders: false,
                contract:[],
                orders:[],
                multipleSelection:[],
                form:{},
                orderButtonLoading: false,
                orderListShow: false,
                loadingdata:false,
                loadingButton:false
            }
        },
        watch: {
            model: {
                handler: function () {
                    this.loadModel();
                    this.orderListShow = false;
                },
                deep: true
            },
            // changeOrder: {
            //     handler: function () {
            //         this.submit()
            //     },
            //     deep: true
            // },
        },
        created(){
            this.loadModel()
        },
        computed: {
            ...mapGetters({
                model: 'contracts/model',
            })
        },
        methods:{
            ...mapActions({
                save: 'payment/contractPayments',
                showContract: 'contracts/show',
            }),
            submit(){
                this.form.contract_id = this.contract.id;
                this.form.debit = 1;
                this.form.rate = 1;
                this.form.contract_balans = this.remainder_prices;
                this.form.orders = this.multipleSelection;
                // console.log(this.form);
                // return ;
                this.loadingButton = true;
                this.save(this.form)
                .then(res => {
                    this.orderList();
                    this.loadingButton = false;
                    this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                })
                .catch(err => {
                    this.loadingButton = false;
                    this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
                });
            },
            orderList(){
                this.remainder_prices = 0;
                this.debtPrices()
                this.changeOrders = false;
                this.loadingdata = true;
                this.orderButtonLoading = true;
                this.showContract({id: this.model.id, orders:true, transactions:true})
                    .then( res => {
                        this.loadingdata = false
                        this.loadModel();
                        this.orderButtonLoading = false;
                        this.orderListShow = true;
                        this.remainder_prices = 0;
                    })
                    .catch(err => {
                        this.loadingdata = false
                    });
            },
            loadModel(){
                this.contract = JSON.parse( JSON.stringify( this.model ));
                this.orders = this.contract.orders ? this.contract.orders : [];
                this.total_prices = this.contract.remainder;
                this.not_paid_prices = 0;
                this.remainder_prices = 0;
                this.changeOrders = false;
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {                
                let ss = _.pullAll(this.multipleSelection,val)
                if (ss.length) {
                    for (let i = 0; i < this.multipleSelection.length; i++) {
                        if(this.multipleSelection[i].id == ss[0].id){
                            this.multipleSelection[i].paying_price = 0;
                        }
                    }
                }
                
                this.multipleSelection = val;

                let Remainder = this.total_prices;
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    const element = this.multipleSelection[i];
                    if (Remainder > 0 && Remainder >= element.not_paid) {
                        element.paying_price = element.not_paid;
                    }
                    else{
                        if (Remainder > 0 && Remainder <= element.not_paid) {
                            element.paying_price = Remainder;
                        }
                    }
                    Remainder =  Number(Remainder) - Number(element.not_paid);   
                }

                this.debtPrice();
                this.orderPrice();
                if (this.multipleSelection.length == 0) {
                    this.debtPrices();
                }
            },
            handleSelectionChangeFor(val) {
                this.multipleSelection = val;
            },
            autoChange(event){
                this.TotalOrdersPrices()
            },
            TotalOrdersPrices(){
                let Remainder = this.total_prices;
                let debit = 0;
                let not_paid_price = 0;

                for (let i = 0; i < this.orders.length; i++) {
                    const element = this.orders[i];

                    if (Remainder > 0 && Remainder >= element.not_paid) {
                        element.paying_price = element.not_paid;
                        this.handleSelectionChangeFor(element)
                        this.$refs.multipleTable.toggleRowSelection(element)
                    }
                    else{
                        if (Remainder > 0 && Remainder <= element.not_paid) {
                            element.paying_price = Remainder;
                            this.handleSelectionChangeFor(element)
                            this.$refs.multipleTable.toggleRowSelection(element)
                        }
                    }
                    Remainder =  Number(Remainder) - Number(element.not_paid);
                    
                }
                this.debtPrice();
                this.orderPrice();
                if (this.multipleSelection.length == 0) {
                    this.debtPrices();
                }
            },
            orderPrice(){
                let price = 0;
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    const element = this.multipleSelection[i];
                    price = Number(price) + Number(element.paying_price);
                }
                this.remainder_prices = this.multipleSelection.length ? (this.total_prices - price) : this.total_prices;
            },
            debtPrice(){
                this.not_paid_prices = 0;
                let paying_price = 0, not_paid = 0;
                for (let i = 0; i < this.orders.length; i++) {
                    const element = this.orders[i];
                    paying_price = paying_price + element.paying_price;
                    not_paid = not_paid + element.not_paid;
                }
                this.not_paid_prices = not_paid - paying_price;
            },
            debtPrices(){
                this.loadModel();
                let debit = 0;
                for (let i = 0; i < this.orders.length; i++) {
                    const element = this.orders[i];
                    debit = Number(debit) + Number(element.not_paid);
                }
                this.not_paid_prices = debit;
            },
            tableRowClassName({row, rowIndex}) {
                if (row.not_paid == row.paying_price) {
                    return 'success-row';
                }
                if (row.not_paid > row.paying_price && row.paying_price != 0) {
                    return 'warning-row';
                }
                else
                    return '';
            }
        }
    }
</script>
<style>
  .el-table .warning-row {
    background: rgb(253, 242, 221);
  }
  .el-table .success-row {
    background: #b8fcbe;
  }
</style>
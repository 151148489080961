<template>
  <div>
    <el-tabs v-model="activeName" class="mt-0 pt-0">
      <el-tab-pane
        :label="$t('message.add_transaction')"
        name="first"
        class="mt-0 pt-0"
      >
        <el-form
          ref="formTransaction"
          :model="form"
          :rules="rules"
          size="mini"
          @keyup.enter.native="submit"
          class="mt-1"
        >
          <el-row :gutter="24">
            <el-col :span="4">
              <el-form-item
                :label="columns.payment_type_id.title"
                prop="payment_type_id"
              >
                <el-select
                  v-model="form.payment_type_id"
                  filterable
                  :placeholder="columns.payment_type_id.title"
                >
                  <el-option
                    v-for="item in paymentTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!-- <el-col :span="5">
                        <el-form-item :label="columns.currency_id.title" prop="currency_id">
                            <el-select v-model="form.currency_id" filterable prop="currency_id" :placeholder="columns.currency_id.title">
                                <el-option
                                v-for="item in currency"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->

            <el-col :span="5">
              <el-form-item :label="columns.price.title" prop="price">
                <el-input
                  v-model="form.price"
                  :placeholder="columns.price.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-form-item :label="columns.date.title" prop="date">
                <el-date-picker
                  type="date"
                  :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
                  v-model="form.date"
                  :placeholder="columns.date.title"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-form-item :label="columns.comment.title" prop="comment">
                <el-input
                  v-model="form.comment"
                  :placeholder="columns.comment.title"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="5">
              <el-button
                style="margin-top: 20px"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit()"
                type="success"
                >{{ $t("message.add_transaction") }}</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('message.history_transaction')" name="second">
        <el-table :data="transactions" style="width: 100%" max-height="300">
          <el-table-column
            prop="payment_type.name"
            :label="$t('message.payment_type')"
          >
          </el-table-column>

          <el-table-column prop="price" :label="$t('message.amount')">
          </el-table-column>
          <!-- <el-table-column
                    prop="currency.name"
                    label="Валюта"
                   >
                    </el-table-column> -->
          <!-- <el-table-column
                    prop="rate"
                    label="Курс"
                   >
                    </el-table-column> -->
          <el-table-column :label="$t('message.date_0')">
            <template slot-scope="scope">
              <span>{{ scope.row.date ? scope.row.date : "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="comment" :label="$t('message.comment')">
          </el-table-column>
          <el-table-column :label="$t('message.user')">
            <template slot-scope="scope">
              <span
                >{{ scope.row.user ? scope.row.user.name : " " }}
                {{ scope.row.user ? scope.row.user.surname : "" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="created_at" :label="$t('message.date')">
            <template slot-scope="scope">
              <span>{{ scope.row.created_at  }}</span>
            </template>
          </el-table-column>

          <el-table-column width="180">
            <template slot-scope="scope">
              <div class="d-flex style_mioni_btu">
                <el-button
                  type="success"
                  style="margin: 0 auto"
                  @click.native.prevent="updateRefund(scope.row.id, true)"
                  :disabled="scope.row.refund ? true : false"
                  :plain="scope.row.refund ? false : true"
                  size="small"
                >
                  <i class="el-icon-refresh-right"></i>
                  {{ $t("message.back") }}
                </el-button>
                <el-button
                  style="margin: 0 auto; display: none"
                  class="mt-1"
                  type="danger"
                  :disabled="scope.row.refund ? true : false"
                  @click.native.prevent="
                    deleteRow(scope.row.id, scope.$index, transactions)
                  "
                  size="small"
                >
                  <i class="el-icon-delete"></i>
                  {{ $t("message.delete") }}
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],
  data() {
    return {
      activeName: "first",
      transactions: [],
    };
  },
  watch: {
    contract: {
      handler: function () {
        this.loadModel();
      },
      deep: true,
    },
  },
  created() {
    this.paymentTypesList();
    this.loadModel();
  },
  computed: {
    ...mapGetters({
      rules: "transaction/rules",
      columns: "transaction/columns",
      paymentTypes: "paymentTypes/list",
      model: "transaction/model",
      contract: "contracts/model",
    }),
  },
  methods: {
    ...mapActions({
      paymentTypesList: "paymentTypes/index",
      save: "payment/paymentStore",
      showContract: "contracts/show",
      updatetTransactionRefund: "transaction/refund",
      delTransaction: "transaction/remove",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
      this.form.date = new Date();
      this.transactions = this.contract.transactions;
    },
    submit() {
      this.$refs["formTransaction"].validate((valid) => {
        if (valid) {
          this.form.typeable_type = "contracts";
          this.form.typeable_type_id = this.contract.id;
          this.form.paymentable_id = this.contract.id;
          this.form.paymentable_type = "App\\Contract";
          this.form.total_price = this.contract.sum;
          this.form.debit = 1;
          this.form.rate = 1;
          this.saveAction();
        }
      });
    },
    saveAction() {
      this.loadingButton = true;
      this.save(this.form)
        .then((res) => {
          // this.$parent.$parent.listChanged();
          this.loadingButton = false;
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
          this.$refs["formTransaction"].resetFields();
          this.form.date = new Date();
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
    },
    fetchData() {
      this.showContract({ id: this.contract.id, transactions: true })
        .then((res) => {})
        .catch((err) => {});
    },
    updateRefund(id, refund) {
      let data = { id: id, refund: refund };
      this.updatetTransactionRefund(data)
        .then((res) => {
          // this.$parent.$parent.listChanged();
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
          this.loadModel();
        })
        .catch((err) => {
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
    },
    deleteRow(id, index, transactions) {
      let data = { transaction_id: id };
      this.delTransaction(data)
        .then((res) => {
          this.$parent.$parent.listChanged();
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerTransaction" });
    },
  },
};
</script>
